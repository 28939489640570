<template>
    <div class="bg-grey-100 dark:bg-grey-600 rounded-md flex flex-col sm:flex-row p-2 w-full">
        <div
            class="cursor-pointer text-lg lg:text-xl 2xl:text-[1.4rem] lg:flex-1 text-center w-full py-2.5 px-10 font-medium tracking-[-0.03em] text-grey-400 dark:text-white"
            v-for="(tab, index) in tabs"
            :key="tab.name"
            :class="tab.selected ? 'bg-white rounded-md shadow-md font-semibold text-purple-950 dark:bg-purple-500 dark:text-white' : ''"
            @click="selectedTab(index)"
        >
            {{ tab.name }}
        </div>
    </div>
</template>

<script setup>
// Assuming the prop 'options' is an array, not an Object
const { options } = defineProps({
    options: Array
});

const tabs = ref(options);
const emit = defineEmits(['tabSelected']);

// On load, emit up the selected tab (first tab by default)
emit('tabSelected', tabs.value[0]);

// Function to handle option selection
const selectedTab = (index) => {
    tabs.value.forEach(option => {
        option.selected = false;
    });
    tabs.value[index].selected = true;
    emit('tabSelected', tabs.value[index]);
};
</script>